<template>
    <is-loading></is-loading>
    <is-alert></is-alert>
    <component :is="layout || 'section'">
        <router-view></router-view>
    </component>
</template>

<script setup>

    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const layout = computed(() => {
        return router.currentRoute.value.meta.layout
    })

    // Variables

    // Methods
    
</script>

<style lang="scss">
    #app {
        font-family: 'Playfair Display';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2C3E50;
    }
    
    // Variable overrides first
    $primary: #0D6EFD;      // #0D6EFD;
    $secondary: #6C757D;    // #6C757D;
    $success: #2B675A;      // #198754;
    $info: #0E221E;         // #0DCAF0;
    $warning: #FFE501;      // #FFC107;
    $danger: #D0021B;       // #DC3545;
    $light: #F8F9FA;        // #F8F9FA;
    $dark: #212529;         // #212529;
    

    // Then import Bootstrap
    @import "../node_modules/bootstrap/scss/bootstrap";
</style>