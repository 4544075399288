import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import _Store from '@/store'
const config = require('../page.config')

// Modules
import Login from 'ROUTER/modules/login.js'
import Dashboard from 'ROUTER/modules/dashboard.js'
import Areas from 'ROUTER/modules/areas'
import Users from 'ROUTER/modules/users'
import Weeks from 'ROUTER/modules/weeks'
import Goals from 'ROUTER/modules/goals'
import Indicators from 'ROUTER/modules/indicators'
import WeeksIndicators from 'ROUTER/modules/weeksIndicators'

// Components
import CSwitch from 'ROUTER/modules/components/switch.js'
import CLoading from 'ROUTER/modules/components/loading.js'
import CModal from 'ROUTER/modules/components/modal.js'
import CInputRecovery from 'ROUTER/modules/components/inputrecovery.js'
import CTable from 'ROUTER/modules/components/table.js'


const routes = [
    ...Login,
    ...Dashboard,
    ...CSwitch,
    ...CLoading,
    ...CModal,
    ...CInputRecovery,
    ...CTable,
    ...Areas,
    ...Users,
    ...Weeks,
    ...Goals,
    ...Indicators,
    ...WeeksIndicators,
    // ...Test
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ 'COMPONENTS/Page404.vue')
    }
]

console.log(config)

const router = createRouter({
    history: config.mode === 'desktop' ? createMemoryHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes
})


// Router before each validations
router.beforeEach( async(to, from, next) => {
    // console.log(to, 'to')
    // console.log(from, 'from')

    /*** CHECK SESSION INTERNAL ***/
    let session = await _Store.dispatch('existSession')
    //console.log('BEFORE EACH ROUTER SESSION:', session)
    
    // Hidden views in specifics cases
    const session_views = ['dashboard', 'areas', 'goals', 'indicators', 'users', 'weeks', 'weeksindicators']
    const no_session_views = ['login', 'recovery']

    if(session == null && session_views.includes(to.name)) {
        next({name: (no_session_views.includes(from.name)) ? from.name : 'login'})
    }
    else if(session !== null) {
        if(no_session_views.includes(to.name)) {
            next({ name: 'dashboard' })
        }
    }

    next()
})

export default router
