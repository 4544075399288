<template>
    <div class="stack-wrapper">
        <template v-for="(avatar, index) in local_list.slice(0, 19)" :key="`avatar-${index}`">

            <img class="avatar" v-if="avatar.picture"
                    :src="avatar.picture" :alt="avatar.avatar"
                    :style="[
                    (local_list.length) <= 5 ? `left: ${index * 15}%` :
                    (local_list.length) > 5 && (local_list.length) <= 10 ? `left: ${index}0%;` :
                    (local_list.length) > 10 ? `left: ${index * 5}%;` :''
                    ]">
            
            <div class="avatar" v-else
                :style="[
                    (local_list.length) <= 5 ? `left: ${index * 15}%` :
                    (local_list.length) > 5 && (local_list.length) <= 10 ? `left: ${index}0%;` :
                    (local_list.length) > 10 ? `left: ${index * 5}%;` :''
                    ]">
                {{ avatar.avatar }}
            </div>
            <div class="avatar" v-if="local_list.length > 20"
                style="left: 95%;">
                {{ local_list.length - local_list.slice(0,19).length}} +
            </div>
        </template>
        
    </div>
</template>

<script setup>

    import { onMounted, ref, watch } from 'vue';

    //props
    const props = defineProps({
        avatar_list: {
            type: Array,
            required: false,
            default: () =>([
                    {picture: '', avatar: 'B1'},
                    {picture: '', avatar: 'B2'},
                    {picture: '', avatar: 'B3'},
                    {picture: '', avatar: 'B4'},
                    {picture: '', avatar: 'B5'},
                    {picture: '', avatar: 'B6'},
                    {picture: '', avatar: 'B7'},
                    {picture: '', avatar: 'B7'},
                    {picture: '', avatar: 'B8'},
                    {picture: 'https://images.dog.ceo/breeds/setter-gordon/n02101006_1888.jpg', avatar: 'B9'},
                    {picture: '', avatar: 'B10'},
                    {picture: '', avatar: 'B11'}
                ])
        }
    })
    //emits
    const emits = defineEmits(['btn:handler'])


    // Hooks

    // Injects

    // Computeds

    // Variables
    const local_list = ref([])

    //watchers
    watch(() => props.avatar_list, (first, second) => {
        local_list.value = [...props.avatar_list]
    })

    // Methods
    
    //created, mounted
    onMounted(() => {
        local_list.value = [...props.avatar_list]
    })

    
</script>


<style lang="scss" scoped>

    $green: #2b675a;
    $dark-gray: #445364;
    $light-gray: #86a3c5;

    .stack-wrapper {
        width: 100%;
        position: relative;
        height: 50px;;


        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: bold;
            object-fit: cover;
            top: 0;

            &:nth-of-type(3n-2) {
                background-color: $green;
            }

            &:nth-of-type(3n-4) {
                background-color: $light-gray;
            }

            &:nth-of-type(3n-6) {
                
                background-color: $dark-gray;
            }
        }
    }

</style>