<template>
    <aside id="Sidebar" class="p-0 d-flex flex-column align-items-center position-relative">
        <div class="row w-100 mt-3">
            <div class="col-12 d-flex flex-center my-2" v-for="(menu_object, index) in menu" :key="`menu-${index}`">
                <!-- without submenu-->
                <button @click="goToOption(index, menu_object.route)" v-if="!menu_object.submenu"
                    class="btn border-0 d-flex flex-column flex-center custom_size"
                    :class="[(menu_active == 'home') ? 'active' : '']"
                    type="button">
                    <img :src="menu_object.icon" :alt="menu_object.label">
                    {{ menu_object.label}} 
                </button>

                <!-- with submenu-->
                <button @click="menu_object.open = true; current_index = index" v-else
                    class="btn border-0 d-flex flex-column flex-center position-relative custom_size"
                    :class="[(menu_active) == 'settings' ? 'active' : '']"
                    type="button">

                    <img  :src="menu_object.icon" :alt="menu_object.label">
                    {{ menu_object.label}} 

                    <div v-if="menu_object.open"
                        @mouseleave="menu_object.open = false"
                        class="row position-absolute bg-white py-2 submenu_wrapper border" style="z-index: 100;">
                       
                            <button v-for="(option, index) in menu_object.submenu" :key="`submenu-${index}`"
                                @click="router.push({ name: option.route}); menu_object.open = false;"
                                class="my-1 text-white py-1 custom-hover border-0"
                                style="width: 180px; z-index: 110;"
                                type="button">
                                {{ option.label}}
                            </button>
                    </div>
                </button>
            </div>
        </div>
        <div class="row w-100 position-absolute justify-content-center" style="bottom: 3%;">
            <button @click.stop="endsession"
                class="btn description-xs border-0 d-flex flex-center flex-column custom_size"
                type="button">

                <div style="max-width: 34px;">
                    <img :src="logout" class="img-fluid" alt="">    
                </div>
                    
                <span class="description-xxs">Cerrar Sesión</span>
                
            </button>
        </div>
    </aside>
</template>

<script setup>
    // Imports
    import { computed, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    // Images
    
    // Icons
    const home = require('ASSETS/icons/sidebar/home.png')
    const gear = require('ASSETS/icons/sidebar/settings.png')
    const chart = require('ASSETS/icons/sidebar/chart.png')
    const logout = require('ASSETS/icons/sidebar/minus_circle')

    // Hooks
    const store = useStore()
    const router = useRouter()

    // Computeds
    const current_router_name = computed(() => {
        return router.currentRoute.value.name
    })
    const menu_active = computed(() => {
        return (current_router_name.value == 'areas' || current_router_name.value == 'goals' ||
            current_router_name.value == 'indicators' || current_router_name.value == 'users' ||
            current_router_name.value == 'weeks' || current_router_name.value == 'weeksindicators') ? 'settings' : 'home'
    })

    // Variables
    const menu  = ref([
        {
            label: 'Home',
            icon: home,
            route: 'dashboard',
        },
        {
            label: 'Configuración',
            icon: gear,
            open: false,
            submenu: [
                {
                    label: 'Áreas',
                    route: 'areas'
                },
                {
                    label: 'Metas',
                    route: 'goals'
                },
                {
                    label: 'Indicadores',
                    route: 'indicators'
                },
                {
                    label: 'Usuarios',
                    route: 'users'
                },
                {
                    label: 'Semanas',
                    route: 'weeks'
                },
                {
                    label: 'Indicador Semana',
                    route: 'weeksindicators'
                }
            ]
        },
        // {
        //     label: 'Avances',
        //     icon: chart
        // },
    ])

    const current_index = ref(0)

    // Methods
    const goToOption = (index, route) => {
        current_index.value = index;
        router.push({ name: route });
        console.log(route)
    }

    const endsession = async () => {
        await sessionStorage.removeItem('imss@session')
        router.push({ name: 'login' })
    }


</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';
    #Sidebar {
        background-color: #0f221e;
        position: fixed;
        width: 160px;
        height: calc(100vh - 80px);

        .active{
            background-color: #86a3c5;
        }

        .custom_size {
            width: 120px; 
            height: 105px;

            @include downPixels(1440px) {
                width: 100px;
                height: 80px;
                font-size: 14px;
            }

            img {
                width: 40px;
                height: 40px;

                @include downPixels(1440px) {
                    width: 25px;
                    height: 25px;
                }
            }

            .submenu_wrapper {
                width: 200px; 
                right: -166%; 
                top: 0%;

                @include downPixels(1440px) {
                    right: -210%; 
                    top: -10%;
                }
            }
        }

        .custom-hover {
            transition: 0.4s background-color;
            background-color: #445364;
            &:hover {
                background-color: #86a3c5
            };
        }
    }
</style>
