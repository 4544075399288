<template>
    <div id="modal" 
        @click.stop="closeModal('overlay')"
        :class="['modal', `${show_modal ? 'd-flex' : 'd-none'}`]" >

        <div @click.stop="closeModal('content')"
            :class="['row modal__container',`bg-${variant}`]" 
            :style="`max-width: ${max_width}px !important;`">

            <!-- Modal Header -->
            <div class="col-12 modal__container--header flex-center pe-4">
                <!-- Modal title -->
                <span class="description-sm text-white pb-1" v-if="title">
                    {{ title }}
                </span>

                <!-- Modal Close button -->
                <button @click.stop="closeModal('button')" 
                    class="modal__container--button bg-transparent position-absolute" 
                    style="right: 10px; top: 0px; z-index: 4;"
                    v-if="show_botton_close">

                    <span class="description-lg text-white close">
                        &times;
                    </span>
                </button>
            </div>

            <!-- Modal Content -->
            <section class="col-12 modal__container--content pt-1 pb-3"
                :style="`${max_height ? 'height: 100%; overflow-y: scroll;' : '' } 
                        max-height: ${max_height}vh !important;` ">
                    <slot>
                        <!-- Slot default -->
                    </slot>
            </section>

        </div>
    </div>
</template>

<script setup> 

    // Props
    const props = defineProps({
        show_modal: {
            type: Boolean,
            required: true,
            default: false
        },
        overlay_close: {
            type: Boolean,
            require: false,
            default: false
        },
        variant: {
            type: String,
            require: false,
            default: 'white',
            validator: function (value) {
                return ['transparent', 'white', 
                    'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'].includes(value)
            }
        },
        max_width: {
            type: Number,
            require: false,
            default: 900,
            validator: function (value) {
                return value >= 1 && value <= 1920
            }
        },
        max_height: {
            type: Number,
            require: false,
            default: null,
            validator: function (value) {
                return value >= 1 && value <= 100 
            }
        },
        title: {
            type: String,
            require: false,
            default: null
        },
        show_botton_close: {
            type: Boolean,
            require: false,
            default: false
        },

    })

    // Emits
    const emits = defineEmits(['close'])

    // Methods
    const closeModal = (type) => {
        if(type === 'content') {
            return
        }
        if(type === 'overlay') {
            if(!props.overlay_close) {
                return
            }
        }
        emits('close')
    }
</script>

<style lang="scss" scoped>
    .modal {
        background-color: #000000bb;
        justify-content: center;
        align-items: center;
        z-index: 9;

        &__container {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            width: 100%;
            z-index: 10;

            &--button {
                border-radius: 0.25rem !important;
                cursor: pointer;
                border: 0px;
            }

            &--header {
                height: 54px;
                background-color: var(--bs-success);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                position: relative;
            }

            &--content {
                
            }
        }
    }
</style>