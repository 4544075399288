import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

// import CUSTOM
import 'ASSETS/scss/custom.scss'


// imports LIBS
import _Request from './libs/sendRequest.js'
import _redirect from './libs/redirect.js'


// import GLOBAL COMPONENTS
import { Alert, Loading, DropDown, Select, Header, Sidebar, FloatMenu, AvatarStack, Card, ProgressIndicator, Switch, Modal, InputRecovery, Table } from './components/index.js'


// TEMPLATES
import T_login from 'TEMPLATES/Tlogin'
import T_dashboard from 'TEMPLATES/Tdashboard'


// Create App
const app = createApp(App)
app.use(store)
app.use(router)
// Templates
app.component('login', T_login)
app.component('dashboard', T_dashboard)
// Components
app.component('is-alert', Alert)
app.component('is-loading', Loading)
app.component('is-sidebar', Sidebar)
app.component('is-dropdown', DropDown)
app.component('is-floatmenu', FloatMenu)
app.component('is-avatarstack', AvatarStack)
app.component('is-select', Select)
app.component('is-header', Header)
app.component('is-progress', ProgressIndicator)
app.component('is-card', Card)
app.component('is-switch', Switch)
app.component('is-modal', Modal)
app.component('is-input-recovery', InputRecovery)
app.component('is-table', Table)
// Libs
app.provide('$sendRequest', _Request)
app.provide('$redirect', _redirect)
// Mount
app.mount('#app')