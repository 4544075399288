import sendRequest from "@/libs/sendRequest";

export const indicators = {
    namespaced: true,
    state: {
        indicator: {},
        indicators: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getIndicator: (state) => (state.indicator),
        getIndicators: (state) => (state.indicators),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setIndicator: (state, payload) => state.indicator = payload,
        setIndicators: (state, payload) => state.indicators = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicators/list', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    let raw_data = response.data.list.result ?? [];
                    const indicator_object = raw_data.map((indicator) => {
                        return {
                            ...indicator,
                            actions: '-'
                        }
                    })

                    commit('setIndicators', indicator_object ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setIndicators', response.data.list.result ?? [])
                }
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicators/view', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                commit('setIndicator', response.data.view ?? {})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        add: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicators/add', 'POST', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Indicador añadido con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                console.log('here')
                let messages = []

                for(let error in response.errors) {
                    console.log(response.errors[error].code)
                    switch(response.errors[error].code) {
                        case 'error_empty_goal_id':
                            messages.push('Error: Meta ID faltante.');
                            break;
                        case 'error_empty_area_id':
                            messages.push('Error: Área ID faltante.');
                            break;
                        case 'error_empty_indicator':
                            messages.push('Error: indicador faltante.');
                            break;
                        case 'error_prefix_indicator_has_already_exist':
                            messages.push('Error: El Área ya existe.');
                            break;
                        case 'error_empty_last_goalreferenc':
                            messages.push('Error: Por favor añada la meta referencia.');
                            break;
                        case 'error_goal_not_found':
                            messages.push('Error: Meta no encontrada.');
                            break;
                        case 'error_area_not_found':
                            messages.push('Error: Área no encontrada.');
                            break;
                        case 'error_inserting_indicator':
                            messages.push('Error: Error al insertar el indicardor.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                console.log(messages)

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        update: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicators/update', 'PUT', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Indicador actualizado con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                console.log('errors')
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id':
                            messages.push('Error: ID faltante.');
                            break;
                        case 'error_empty_goal_id':
                            messages.push('Error: Meta ID faltante.');
                            break;
                        case 'error_empty_area_id':
                            messages.push('Error: Área ID faltante.');
                            break;
                        case 'error_prefix_indicator_has_already_exist':
                            messages.push('Error: El Área ya existe.');
                            break;
                        case 'error_empty_last_goalreferenc':
                            messages.push('Error: Por favor añada la meta referencia.');
                            break;
                        case 'error_goal_not_found':
                            messages.push('Error: Meta no encontrada.');
                            break;
                        case 'error_area_not_found':
                            messages.push('Error: Área no encontrada.');
                            break;
                        case 'error_updating_indicator':
                            messages.push('Error: Error al actualizar el indicardor.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setError', true)

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        delete: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicators/delete','DELETE', null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Se ha eliminado el indicador con éxito.'
                    }, {root: true})
                
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let index in response.errors) {
                    switch(response.errors[index].code) {
                        case 'error_empty_id':
                            messages.push('Error: ID faltante');
                            break;
                        case 'error_indicators_no_update':
                            messages.push('Error: Un error ha ocurrido al intentar actualizar la informacion..');
                            break;
                        default:
                            messages.push('Un error ha ocurrido. Intente más tarde.')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)

            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Se ha perdido la conexión con la API.'
                    }, {root: true})

                commit('setError', true)
            }
        }
    }
}