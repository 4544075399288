<template>
    <div id="template-login" class="p-0">
        <is-header :show_menu_boton="false"
            class="position-absolute w-100" 
            style="top: 0px;"/>

        <slot></slot>

        <img :src="pleca" 
            alt=""
            class="position-absolute w-100" 
            style="bottom: 0px;"
            height="60px" >
	</div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const pleca = require('ASSETS/images/pleca.png')

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables

    // Methods

</script>


<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #template-login {
        overflow: hidden;
        height: 100vh;
        width: 100vw;
    }
</style>