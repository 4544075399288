<template>
    <section class="scroll table-responsive p-0 m-0">

        <table :class="`table table-${theme}
            ${(borderless) ? 'table-borderless' : ''}
            ${(bordered) ? 'table-bordered' : ''}
            ${(striped) ? 'table-striped' : ''}
            ${(hover) ? 'table-hover' : ''}
            ${(small) ? 'table-sm' : ''}`">

            <thead>
                <tr :class="`text-${header_text_color}`">

                    <template v-for="(header, header_index) in get_headers" 
                        :key="`${header_index}_header`">  
                            <th :class="`table__header ${(theme !== 'light' ? `bg-${theme} text-white` : '')} px-3 py-0`" 
                                v-if="checkHeader(header)">

                                    <span class="description-xs">
                                        {{ findLabel(header) }}
                                    </span>
                            </th>
                    </template>

                    <!-- Missing Headers -->
                    <template v-for="(m_header, index_m_header) in missing_headers" 
                        :key="`${index_m_header}_missing_header`">
                            <th :class="`table__header ${(theme !== 'light' ? `bg-${theme} text-white` : '')} p-3`" >

                                <span class="description-xs">
                                    {{ findLabel(m_header) }}
                                </span>
                            </th>
                    </template>
                </tr>
            </thead>

            <tbody v-if="data !== null">
                <tr v-for="(row_item, row_index) in data" :key="row_index" 
                    :class="`table__row p-1`">

                    <template v-for="(col, col_index) in row_item" 
                        :key="`${col_index}_item`">

                            <td :class="`text-${body_text_color} ${(body_white) ? 'bg-white' : ''}`"
                                v-if="checkHeader(col_index)">

                                <slot :name="col_index" 
                                    v-bind:value="row_item[col_index]" 
                                    v-bind:row="row_item">

                                        <span class="description-xxs">
                                            {{ col }}
                                        </span>
                                </slot>
                            </td>
                    </template>

                    <template v-for="(col_missing, col_missing_index) in missing_headers" 
                        :key="`${col_missing_index}_missing_item`">

                            <td :class="`text-${body_text_color} ${(body_white) ? 'bg-white' : ''}`">
                                <slot :name="col_missing" 
                                    v-bind:row="row_item">

                                        <span class="description-xxs">
                                            {{ col_missing }}
                                        </span>
                                </slot>
                            </td>
                    </template>

                </tr>
            </tbody>

        </table>

        <div class="col-12 text-center py-3" v-if="data == null || data.length == 0">
            <span class="description-xxs">
                No se encontraron registros
            </span>
        </div>
    </section>

    <div class="pt-2" v-if="pagination">

        <div class="col-12 d-flex justify-content-between">
            <div class="flex-center">
                <span class="description-xs">Items por página: </span>    
            
                <select v-model="local_items_per_page" 
                    @change="changeItemsPerPage()" 
                    class="form-control description-xxs select-items ms-2">

                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>

            <div class="flex-center">
                <button @click="previousPage()"
                    class="btn btn-outline-success btn-pill py-1 px-3 mx-1"
                    :disabled="current_page <= 1">
                        <img :src="icon_arrow_left" class="">
                </button>

                <section class="flex-center mx-2">
                    <input v-model="local_current_page" 
                        @keydown.enter="changePage()"
                        class="form-control description-xxs me-1" 
                        style="max-width: 50px;">
                    <span class="description-xs"> / {{ total_pages }}</span>
                </section>
                

                <button @click="nextPage()"
                    class="btn btn-success btn-pill py-1 px-3 mx-1"
                    :disabled="current_page >= total_pages">
                        <img :src="icon_arrow_right" class="">
                </button>
            </div>
        </div>
    </div>
</template>

<script setup> 
    import { ref, computed, watch, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    import icon_arrow_left from 'ASSETS/icons/arrow_left'
    import icon_arrow_right from 'ASSETS/icons/arrow_right'

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Props
    const props = defineProps({
        allow_headers: {
            type: Array,
            required: true,
            default: []
        },
        data: {
            type: [null, Array],
            required: true,
            default: []
        },
        theme: {
            type: String,
            required: false,
            default: 'success',
            validator: function (value) {
                return ['primary','secondary','success','warning', 'danger', 'info', 'light', 'dark'].includes(value)
            }
        },
        header_text_color: {
            type: String,
            required: false,
            default: 'dark',
            validator: function (value) {
                return ['primary','secondary','success','warning', 'danger', 'info', 'light', 'dark'].includes(value)
            }
        },
        body_text_color: {
            type: String,
            required: false,
            default: 'dark',
            validator: function (value) {
                return ['primary','secondary','success','warning', 'danger', 'info', 'light', 'dark'].includes(value)
            }
        },
        body_white: {
            type: Boolean,
            required: false,
            default: true
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false
        },
        bordered: {
            type: Boolean,
            required: false,
            default: false
        },
        striped: {
            type: Boolean,
            required: false,
            default: false
        },
        hover: {
            type: Boolean,
            required: false,
            default: true
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        pagination: {
            type: Boolean,
            required: false,
            default: false
        },
        current_page: {
            type: Number,
            required: false,
            default: 1
        },
        total_pages: {
            type: Number,
            required: false,
            default: 1
        },
        items_per_page: {
            type: Number,
            required: false,
            default: 10
        },
    })

    // Emits
    const emits = defineEmits(['change_items_per_page', 'previous_page', 'next_page', 'change_page'])

    // Variables
    let local_data = null
    let local_items_per_page = ref(null)
    let local_current_page = ref(null)

    // Injects

    // Variables

    // Watch
    watch(() => props.current_page, (first, second) => {
        local_current_page.value = props.current_page
    })

    // Computeds
    const get_headers = computed(() => {
        if(props.data !== null && props.data.length > 0) {
            return Object.keys(props.data[0])
        }
        return []
    })
    const allow_key_headers = computed(() => {
        let allow = []

        for(let i in props.allow_headers) {
            if(isObject(props.allow_headers[i]) && props.allow_headers[i].key !== null ) {
                allow.push(props.allow_headers[i].key)
            }
            else {
                allow.push(props.allow_headers[i])   
            }
        }
        return allow
    })
    const missing_headers = computed(() => {
        let difference = allow_key_headers.value.filter(x => !get_headers.value.includes(x))
        return difference
    })


    // Methods
    // Methods For headers
    const checkHeader = (value) => {
        return value !== null && allow_key_headers.value.includes(value)
    }
    const findLabel = (key) => {
        for(let i in props.allow_headers) {
            let item = props.allow_headers[i]

            if(isObject(item) && item.key !== undefined && item.key !== null) {
                if(item.key == key) {
                    return item.label
                }
            }
            else {
                if(item == key) {
                    return item
                }
            }
        }
    }

    const isObject = (value) => {
        return typeof(value) === 'object' && !Array.isArray(value) && value !== null
    }


    // Methods For Emits
    const changeItemsPerPage = (event) => {
        emits('change_items_per_page', { items_per_page: local_items_per_page.value, page: 1 })
    }

    const previousPage = () => {
        if(props.current_page > 1) {
            emits('previous_page', { previous_page: props.current_page - 1 })    
        }
        else {
            emits('previous_page', { previous_page: 1 })
        }
    }

    const changePage = () => {
        if(/^\d+$/.test(local_current_page.value)) {
            if(local_current_page.value >= 1 && local_current_page.value <= props.total_pages) {
                emits('change_page', { new_page: local_current_page.value })    
            }
            else {
                local_current_page.value = props.current_page
            }
        }
        else {
            local_current_page.value = props.current_page
        }
    }

    const nextPage = () => {
        if(props.current_page < props.total_pages) {
            emits('next_page', { next_page: props.current_page + 1 })
        }
        else {
            emits('next_page', { next_page: props.total_pages })
        }
    }

    local_items_per_page.value = props.items_per_page
    local_current_page.value = props.current_page

</script>

<style lang="scss" scoped>
    .table {
        padding: 0px;
        margin: 0px;

        &__header {
            vertical-align: middle;
            text-align: center;
            height: 30px;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

        &__row {
            vertical-align: middle;
            text-align: center;
        }
    }

    .select-items {
        width: 80px !important;
    }

</style>