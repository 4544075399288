<template>
    <header class="header-container w-100">
        
        <button @click.stop="hideMenu"
            class="btn me-3"
            style="width: 160px;"
            type="button"
            v-if="show_menu_boton">

            <img width="36" height="36"
                :src="menu_icon" alt="">
        </button>
        
        <div class="p-4">
            <img height="36" :src="logo_mexico" alt="Gobierno de México">
        </div>
    </header>

    
</template>


<script setup>
    const menu_icon = require('ASSETS/icons/menu.svg')
    const logo_mexico = require('ASSETS/images/logomexico.png')
    import { ref } from 'vue';

    // Hooks

    // Props
    const props = defineProps({
        show_menu_boton: {
            type: Boolean,
            required: false,
            default: true
        }
    })

    // Injects

    // Computeds

    // Variables
    
    //emits
    const emits = defineEmits(['hide']);

    // Methods

    const hideMenu = () => {
        emits('hide');
    }
</script>

<style lang="scss" scoped>
    .header-container {
        background-color: #0f221e;
        height: 80px;
        display: flex
    }
</style>