import sendRequest from "@/libs/sendRequest";

export const goals = {
    namespaced: true,
    state: {
        goal: {},
        goals: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getGoal: (state) => (state.goal),
        getGoals: (state) => (state.goals),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setGoal: (state, payload) => state.goal = payload,
        setGoals: (state, payload) => state.goals = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('goals/list', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    let raw_data = response.data.list.result ?? [];
                    const area_object = raw_data.map((goal) => {
                        return {
                            ...goal,
                            actions: '-'
                        }
                    })

                    commit('setGoals', area_object ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setGoals', response.data.list ?? [])
                }
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('goals/view', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                commit('setGoal', response.data.view ?? {})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        add: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('goals/add', 'POST', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Meta añadida con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_goal':
                            messages.push('Error: Por favor añada una meta.');
                            break;
                        case 'error_goal_has_already_exist':
                            messages.push('Error: La meta ya existe.');
                            break;
                        case 'error_inserting_goal':
                            messages.push('Error: Error al insertar.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        update: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('goals/update', 'PUT', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Meta actualizada con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id':
                            messages.push('Error: Id vacio.');
                            break;
                        case 'error_empty_goal':
                            messages.push('Error: Por favor añada una meta.');
                            break;
                        case 'error_goal_has_already_exist':
                            messages.push('Error: La meta ya existe.');
                            break;
                        case 'error_updating_area':
                            messages.push('Error: Un error al actualizar ha ocurrido..');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        delete: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('goals/delete','DELETE', null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Se ha eliminado la meta con éxito.'
                    }, {root: true})

                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let index in response.errors) {
                    switch(response.errors[index].code) {
                        case 'error_empty_id':
                            messages.push('Error: ID faltante');
                            break;
                        case 'error_goals_no_update':
                            messages.push('Error: Ha ocurrido un error al intentar actualizar la información.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido. Intente más tarde.')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)

            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Se ha perdido la conexión con la API.'
                    }, {root: true})

                commit('setError', true)
            }
        }
    }
}