<template>
    <div id="template-dashboard" class="">
        <is-header @hide="show_menu = !show_menu"/>

        <div class="d-flex">
            <is-sidebar v-if="show_menu"></is-sidebar>
            <div class="h-100 w-100" 
                style="max-height: calc(100vh - 80px); height: 100% !important; overflow-y: scroll;">

                <slot>
                    
                </slot>

                <img class="w-100" height="60px" :src="pleca" alt="">
            </div>

        </div>
	</div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    const pleca = require('ASSETS/images/pleca.png')

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables
    const show_menu = ref(true);

    // Methods

</script>


<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #template-dashboard {
        overflow: hidden;
        min-height: 100vh;
        width: 100vw;

    }
</style>