import sendRequest from "@/libs/sendRequest";

export const weeks = {
    namespaced: true,
    state: {
        week: {},
        weeks: [],
        year_options: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getWeek: (state) => (state.week),
        getWeeks: (state) => (state.weeks),
        getYearOptions: (state) => (state.year_options),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setWeek: (state, payload) => state.week = payload,
        setWeeks: (state, payload) => state.weeks = payload,
        setYearOptions: (state, payload) => state.year_options = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('weeks/list', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    let raw_data = response.data.list.result ?? [];
                    const week_object = raw_data.map((week) => {
                        return {
                            ...week,
                            actions: '-'
                        }
                    })

                    commit('setWeeks', week_object ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setWeeks', response.data.list ?? [])
                }
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        options: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;
            const response = await sendRequest('weeks/list', 'GET', null, { datatable: false, order_asc: false }, custom_headers);

            if(response.success && response.data) {
                let raw_data = response.data.list ?? [];
                let options = []
                
                raw_data.forEach((data) => {
                    
                    let index = options.findIndex((year) => year.id == data.anio)

                    if(index < 0) {
                        options.push({id: data.anio, label: data.anio })
                    }
                })

                options.sort((a,b) => a.id - b.id)

                options = [...options, { id: options[options.length - 1].id + 1, label: options[options.length - 1].id + 1, }]

                commit('setYearOptions', options)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('weeks/view', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                commit('setWeek', response.data.view ?? {})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        add: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('weeks/add', 'POST', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Semana añadida con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_weeks':
                            messages.push('Error: Por favor añada una semana.');
                            break;
                        case 'error_empty_year':
                            messages.push('Error: Por favor añada un año.');
                            break;
                        case 'error_week_has_already_exist':
                            messages.push('Error: La semana ya existe.');
                            break;
                        case 'error_inserting_week':
                            messages.push('Error: Error al insertar.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        update: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('weeks/update', 'PUT', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Semana actualizada con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id':
                            messages.push('Error: Id vacio.');
                            break;
                        case 'error_empty_weeks':
                            messages.push('Error: Por favor añada una semana.');
                            break;
                        case 'error_empty_year':
                            messages.push('Erorr: Por favor añada una año.');
                            break;
                        case 'error_week_has_already_exist':
                            messages.push('Error: La semana ya existe.');
                            break;
                        case 'error_updating_week':
                            messages.push('Error: Un error al actualizar ha ocurrido..');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        delete: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('weeks/delete','DELETE', null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Se ha eliminado la semana con éxito.'
                    }, {root: true})

                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let index in response.errors) {
                    switch(response.errors[index].code) {
                        case 'error_empty_id':
                            messages.push('Error: ID faltante');
                            break;
                        case 'error_week_no_update':
                            messages.push('Error: Error al intentar actualizar la información.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido. Intente más tarde.')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)

            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Se ha perdido la conexión con la API.'
                    }, {root: true})

                commit('setError', true)
            }
        }
    }
}