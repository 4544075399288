import { createStore } from 'vuex'
import { areas } from './modules/areas'
import { weeks } from './modules/weeks'
import { goals } from './modules/goals'
import { indicators } from './modules/indicators'
import { users } from './modules/users'
import { rel_indicators } from './modules/relIndicator'
import sendRequest from '@/libs/sendRequest'
import router from '@/router'

export default createStore({
    state: {
        loading: {
            show: false,
            type: 'ellipsis',
            title: 'Cargando',
            subtitle: 'un momento...'
        },
        alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
        session: null
    },
    getters: {
        getSession: state => {
            state.session = sessionStorage.getItem('imss@session') || null
            return state.session
        },
        getLoading: (state) => {
            return state.loading
        },
        getAlert: (state) => {
            return state.alert
        },
        getHeaders (state) {
            return {
                ...CONFIG.server[CONFIG.env].headers,
                session: JSON.parse(sessionStorage.getItem('imss@session'))
            }
        },
    },
    mutations: {
        setLoading: (state, new_load) => {
            state.loading.show = new_load.show
            state.loading.type = (new_load.type !== undefined && new_load.type !== null) ? new_load.type : 'ellipsis'
            state.loading.title = (new_load.title !== undefined && new_load.title !== null) ? new_load.title : 'Cargando'
            state.loading.subtitle = (new_load.subtitle !== undefined && new_load.subtitle !== null) ? new_load.subtitle : 'Espere un momento...'
            state.loading.timeout = (new_load.timeout !== undefined && new_load.timeout !== null) ? new_load.timeout : null

            if(state.loading.timeout !== null) {
                setTimeout(() => {
                    state.loading.show = false
                }, state.loading.timeout)
            }
        },
        setAlert: (state, new_alert) => {
            state.alert.open = new_alert.open
            state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
            state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
            state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
            state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

            if(state.alert.timeout !== null) {
                setTimeout(() => {
                    state.alert.open = false
                }, state.alert.timeout)
            }
        },
        setSession: (state, session_data) => {
            state.session = session_data
            sessionStorage.setItem('imss@session', JSON.stringify(session_data))
        }
    },
    actions: {
        // Session
        createSession: ({commit}, session_data) => {
            commit('setSession', session_data)
        },
        existSession: (state) => {
            state.session = sessionStorage.getItem('imss@session') || null
            // try { state.session = JSON.parse(state.session) }
            // catch(error) { state.session = null }
            return state.session
        },
        deleteSession: (state) => {
            sessionStorage.removeItem('imss@session')
            state.session = null
        },
        // Loading
        showLoading: ({commit}) => {
            commit('setLoading', { show: true })
        },
        hiddenLoading: ({commit}) => {
            commit('setLoading', { show: false })
        },
        // Alerts
        showAlert: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message
            })
        },
        showError: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message,
                variant: 'danger'
            })
        },
        login: async({commit}, payload) => {
            const response = await sendRequest('auth/login', 'POST', payload)

            if(response.success && response) {
                console.log(response.data)
                //@TODO notification success
                commit('setSession', response.data?.session.session)

                router.push({name: 'dashboard'})
            }
        
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    variant: 'danger', 
                    message: 'Contraseña o Usuario incorrecto..'
                })
            }

            if(!response.success) {
                commit('setAlert', {
                    open: true, 
                    variant: 'danger', 
                    message: 'Contraseña o Usuario incorrecto..'
                })
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        }
    },
    modules: {
        areas, weeks, goals, users, rel_indicators, indicators
    }
})
