<template>
    <section :class="`switch-container ${inline ? 'flex-between-center' : 'd-inline-block'}`">
        <span :class="`description-${label_size}`" v-if="label">
            {{ label }}
        </span><br v-if="label">

        <label :class="`switch switch-${variant}`">
            <input :checked="modelValue" 
                @change="$emit('update:modelValue', $event.target.checked)"
                type="checkbox">
            <span :class="`slider slider-${variant}`"></span>
        </label>
    </section>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const store = useStore()

    // Props
    const props = defineProps({
        _id: {
            type: String,
            required: false,
            default: `switch-${Math.random() * 100}`
        },
        modelValue: {
            required: false,
            type: [Boolean, String],
            default: () => {
                return false
            }
        },
        variant: {
            type: String,
            required: false,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'].includes(value)
            }
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        label_size: {
            type: String,
            required: false,
            default: 'xs',
            validator: function (value) {
                return ['xxs', 'xs', 'sm', 'md', 'lg'].includes(value)
            }
        },
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    // Emits
    const emit = defineEmits(['update:modelValue'])

    // Injects

    // Computeds

    // Variables

    // Methods

</script>

<style lang="scss" scoped>
    
    .switch-container {


        .switch {
            position: relative;
            display: inline-block;
            width: 52px;
            height: 28px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }

        // Change position switch
        input:checked {
            +.slider:before {
                -webkit-transform: translateX(24px);
                -ms-transform: translateX(24px);
                transform: translateX(24px);
            }
        }


        /*⊱⊱════════════════════ Switch Color Styles ════════════════════⊰⊰*/

        $colors: primary, secondary, success, warning, danger, info, light, dark;

        // ⊱⊱════════════════════════════════════════⊱⊱ [Switch Background]
        @each $color in $colors {
            .switch-#{$color} {
                input {
                    &:checked + .slider {
                        background-color: var(--bs-#{$color});
                    }

                    &:hover + .slider {
                        box-shadow: 0 0 10px var(--bs-#{$color});
                    }
                }
            }
        }

        // ⊱⊱════════════════════════════════════════⊱⊱ [Switch Slider Circle]
        
        input:checked {
            @each $color in $colors {
                +.slider-#{$color}:before {
                    background: white !important; // var(--bs-#{$color}) !important;
                }
            }
        }

    }

</style>

