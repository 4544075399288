module.exports = {
	env: "prod",
    mode: 'desktop', //'web'
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3000',
			headers: {
				// 'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer Q4fvGBUuuBQTY0X1mog2ylZOzWrm9skRFMnL1MGhdruCRszLsU0Rkm8clIzBTReZ',
				'reference': 'com.control-imss.web'
			}
		},
		dev: {
			page_url: 'https://imss.services',
			api: 'https://api.imss.services',
			headers: {
				// 'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer Q4fvGBUuuBQTY0X1mog2ylZOzWrm9skRFMnL1MGhdruCRszLsU0Rkm8clIzBTReZ',
				'reference': 'com.control-imss.web'
			}
		},
		prod: {
			page_url: 'https://imss.services',
			api: 'https://api.imss.services',
			headers: {
				// 'Accept': 'application/json, text/plain, /',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer Q4fvGBUuuBQTY0X1mog2ylZOzWrm9skRFMnL1MGhdruCRszLsU0Rkm8clIzBTReZ',
				'reference': 'com.control-imss.web'
			}
		}
	}
}