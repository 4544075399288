<template>
    <div class="floatmenu-container">
        <button @click.stop="toggleMenu" v-if="!show_menu"
            class="open-btn description-xxs"
            type="button">
            Acciones
        </button>

        <ul  @mouseleave="toggleMenu" v-if="show_menu"
            class="btn-container" >
            <li v-for="(btn, index) in btn_list" :key="`menu-option-${index}`">
                <button @click.stop="sendAction(btn.id)"
                    class="btn-item description-xxs"
                    :class="[`bg-${variant}`]"
                    type="button">
                    {{ btn.label }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    //props
    const props = defineProps({
        label: {
            type: String,
            required: false,
            default: 'Acciones'
        },
        btn_list: {
            type: Array,
            required: false,
            default: () =>([
                    {id: 1, label: 'button 1'},
                    {id: 2, label: 'button 2'},
                    {id: 3, label: 'button 3'}
                ])
        },
        variant: {
            type: String,
            required: false, 
            default: 'success'
        }
    })
    //emits
    const emits = defineEmits(['btn:handler'])


    // Hooks

    // Injects

    // Computeds

    // Variables
    const show_menu = ref(false);

    // Methods
    const toggleMenu = () => {
        show_menu.value = !show_menu.value;
    }

    const sendAction = (id) => {
        console.log('float:', id)
        emits('btn:handler', id )
        show_menu.value = !show_menu.value
    }

</script>

<style lang="scss" scoped>

    .floatmenu-container {

        .open-btn {
            width: 100%;
            height: 30px;
            border: 1px solid black;
            border-radius: 10px;
            background-color: transparent;
        }

        .btn-container {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 1px 0;

                .btn-item {
                    width: 100%;
                    border: none;
                    color: white;
                    font-weight: bold;
                    padding: 3px 0px;
                }
            }

            li:first-of-type .btn-item{
                margin: 0;;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            li:last-of-type .btn-item{
                margin: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            
        }

    }

</style>
