import sendRequest from "@/libs/sendRequest";

export const rel_indicators = {
    namespaced: true,
    state: {
        rel_indicator: {},
        rel_indicators: [],
        error: false,
        last_page: null,
        total_pages: null,
        id_area: 1,
        id_week: null
    },
    getters: {
        getRelIndicator: (state) => (state.rel_indicator),
        getRelIndicators: (state) => (state.rel_indicators),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error),
        getIdArea: (state) => (state.id_area),
        getIdWeek: (state) => (state.id_week)
    },
    mutations: {
        setRelIndicator: (state, payload) => state.rel_indicator = payload,
        setRelIndicators: (state, payload) => state.rel_indicators = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
        setIdArea: (state, payload) => state.id_area = payload,
        setIdWeek: (state, payload) => state.id_week = payload,
    },
    actions: {
        list: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicatorsweeks/list', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    if(response.data.list.result) {
                        let raw_data = response.data.list.result ?? [];
                        const rel_indicator_object = raw_data.map((rel_indicator) => {
                            return {
                                ...rel_indicator,
                                actions: '-'
                            }
                        })

                        commit('setRelIndicators', rel_indicator_object ?? [])
                        commit('setTotalPages', response.data.list.total_pages)
                        if(response.data.list.total_pages == query.page) {
                            commit('setLastPage', true)
                        }
                        else {
                            commit('setLastPage', false)
                        }
                    }
                    else {
                        commit('setRelIndicators', response.data.list ?? [])
                    }
                }
                else {
                    commit('setRelIndicators', response.data.list ?? [])
                }
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicatorsweeks/view', 'GET', null, query, custom_headers);

            if(response.success && response.data) {
                commit('setRelIndicator', response.data.view ?? {})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio al conexión con la API')
            }
        },
        add: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicatorsweeks/add', 'POST', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Indicador semana añadido con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_indicator_id':
                            messages.push('Error: Por favor seleccione un indicador.');
                            break;
                        case 'error_empty_week_id':
                            messages.push('Error: Por favor seleccione una semana.');
                            break;
                        case 'error_empty_value':
                            messages.push('Error: Por favor añada un valor.');
                            break;
                        case 'error_indicator_not_found':
                            messages.push('Error: no se encontró el indicador seleccionado');
                            break;
                        case 'error_week_not_found':
                            messages.push('Error: no se encontró la semana seleccionada.');
                            break;
                        case 'error_inserting_indicator_week':
                            messages.push('Error: error al procesar la inserción.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
        },
        update: async({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicatorsweeks/update', 'PUT', body, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Área actualizada con éxito'
                    }, {root: true})
                commit('setError', false)

            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id':
                            messages.push('Error: Falta ID indicador semana.');
                            break;
                        case 'error_empty_indicator_id':
                            messages.push('Error: Por favor seleccione un indicador.');
                            break;
                        case 'error_empty_week_id':
                            messages.push('Error: Por favor seleccione una semana.');
                            break;
                        case 'error_empty_value':
                            messages.push('Error: Por favor añada un valor.');
                            break;
                        case 'error_indicator_not_found':
                            messages.push('Error: no se encontró el indicador seleccionado');
                            break;
                        case 'error_week_not_found':
                            messages.push('Error: no se encontró la semana seleccionada.');
                            break;
                        case 'error_updating_indicator_week':
                            messages.push('Error: error al procesar la inserción.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'
                    }, {root: true})
            }
            
        },
        delete: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('indicatorsweeks/delete','DELETE', null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Se ha eliminado el indicador por semana con éxito.'
                    }, {root: true})
                
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let index in response.errors) {
                    switch(response.errors[index].code) {
                        case 'error_empty_id':
                            messages.push('Error: ID faltante');
                            break;
                        case 'error_empty_id':
                            messages.push('Error: ID faltante');
                            break;
                        default:
                            messages.push('Un error ha ocurrido. Intente más tarde.')
                            break;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})

                commit('setError', true)

            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Se ha perdido la conexión con la API.'
                    }, {root: true})

                commit('setError', true)
            }
        }
    }
}