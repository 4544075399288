<template>
    <button @click="selectItem()" class="card-wrapper" type="button">

        <img :src="icon || shield_icon" alt=""
            class="card--icon">

        <span class="description-xl poppins-bold" 
            style="color: #53a09e">
                {{ title }}
        </span>
        <span class="description-xs text-white">
            {{ description }}
        </span>
    </button>
</template>

<script setup>
    const shield_icon = require('ASSETS/icons/shield')

    // Props
    const props = defineProps({
        id: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: 'DA'
        },
        description: {
            type: String,
            required: false,
            default: 'Dirección de Prestaciones Médicas.'
        },
        icon: {
            type: String,
            required: false,
            default: ''
        }
    })

    // Emits
    const emit = defineEmits(['select'])

    // Methods 
    const selectItem = () => {
        emit('select', { id: props.id, area: props.title })
    }

</script>

<style lang="scss" scoped>
     @import 'ASSETS/scss/minxin.scss';

    .card-wrapper {
        text-align: start;
        border: none;
        border-top-left-radius: 50px;
        border: none;
        border-bottom-right-radius: 50px;
        background: linear-gradient(200deg, #2b6158, #26273b);
        box-shadow: 0px 20px 10px 0px rgba(0,0,0, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: end;
        position: relative;
        padding: 2rem;
        width: 300px;
        height: 212px;

        @include downPixels(1440px) {
            padding: 1rem;
            width: 250px;
            height: 180px;
        }

        .card--icon {
            position: absolute;
            top: 1.7rem;
            right: 1.5rem;
            width: 50px;

            @include downPixels(1440px) {
                width: 50px;
                top: 0.5rem;
                right: 1.5rem;
            }
        }

        .card--title{
            color: white;
            font-weight: bolder;
            font-size: 35px;
            margin-bottom: 0;
            @include downPixels(1440px) {
                font-size: 25px;
            }
        }

        .card--description {
            color: white;
            font-size: 20px;
           @include downPixels(1440px) {
                font-size: 18px;
            }
        }
    }

</style>